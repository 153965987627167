import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { DetailsExtra } from 'src/app/data/service/DetailsExtra'
import { DownloadNavigationData } from 'src/app/data/service/DownloadNavigationData'
import { DownloadsData } from 'src/app/data/service/DownloadsData'
import { BaseWebService } from './web-services/base-web.service'

@Injectable()
export class DownloadsService extends BaseWebService {
    constructor(private httpService: HttpClient) {
        super(httpService)
    }

    getDetails(id: number): Promise<DownloadsData> {
        return this.postAndReturn<DownloadsData>({
            type: 'downloads',
            action: 'getDownloadById',
            searchId: id
        }).then((download: DownloadsData) => {
            if (!download) { return download }

            Object.setPrototypeOf(download, new DownloadsData())
            download.Details?.Extra?.forEach(e => Object.setPrototypeOf(e, new DetailsExtra()))

            return download
        })
    }

    getDownloadsList(): Promise<DownloadNavigationData[]> {
        return this.postAndReturnList<DownloadNavigationData>({
            type: 'downloads',
            action: 'getDownloadsList'
        }).then((downloads: DownloadNavigationData[]) => {
            if (!downloads) { return downloads }
            downloads.forEach(dl => Object.setPrototypeOf(dl, new DownloadNavigationData()))

            return downloads
        })
    }
}
