import { ModuleWithProviders, NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { NgbModule } from '@ng-bootstrap/ng-bootstrap'
import { CustomDatePipe } from 'src/pipes/date.pipe'
import { SafePipe } from 'src/pipes/safe.pipe'
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome'
import { DisableLinkDirective } from 'src/directives/disable-link.directive'

@NgModule({
    imports: [
        FontAwesomeModule,
        CommonModule,
        NgbModule
    ],
    declarations: [
        SafePipe,
        CustomDatePipe,
        DisableLinkDirective
    ],
    exports: [
        CommonModule,
        NgbModule,
        FontAwesomeModule,
        SafePipe,
        CustomDatePipe,
        DisableLinkDirective
    ]
})
export class SharedModule {
    static forRoot(): ModuleWithProviders<SharedModule> {
        return {
            ngModule: SharedModule
        }
    }
}
