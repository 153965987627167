import {
    Component, OnInit, Directive, TemplateRef, ContentChild, Input,
    EventEmitter, Output, AfterViewInit, OnDestroy, ChangeDetectorRef
} from '@angular/core'
import { Router, NavigationStart } from '@angular/router'
import { Subscription } from 'rxjs'
import { filter } from 'rxjs/operators'

declare var $: any

@Directive({selector: 'ng-template[appModalTitle]'})
    export class AppModalTitleDirective {
    constructor(public templateRef: TemplateRef<any>) {}
}

@Directive({selector: 'ng-template[appModalBody]'})
    export class AppModalBodyDirective {
    constructor(public templateRef: TemplateRef<any>) {}
}

@Directive({selector: 'ng-template[appModalFooter]'})
    export class AppModalFooterDirective {
    constructor(public templateRef: TemplateRef<any>) {}
}

@Component({
    selector: 'app-modal',
    templateUrl: './modal.component.html',
    styleUrls: ['./modal.component.scss']
})
export class ModalComponent implements OnInit, AfterViewInit, OnDestroy {
    @ContentChild(AppModalTitleDirective) titleTemplate: AppModalTitleDirective
    @ContentChild(AppModalBodyDirective) bodyTemplate: AppModalBodyDirective
    @ContentChild(AppModalFooterDirective) footerTemplate: AppModalFooterDirective

    @Input() title: string
    @Input() text: string
    @Input() largeModal: boolean
    @Input() buttons: Array<{
        text: string,
        styleClass: string,
        action: Function,
        primary: boolean,
        dismiss?: boolean
    }>

    @Output() modalIdChange = new EventEmitter<string>()
    @Output() onClose = new EventEmitter()

    public modalId: string
    public isOpen: boolean = false

    private $modal: any
    private subscriptions: Subscription[] = []
    private isDestroyed: boolean = false

    constructor(
        private router: Router,
        private cdRef: ChangeDetectorRef
    ) {}

    ngOnInit() {
        this.modalId = this.randomString(15)
        this.modalIdChange.emit(this.modalId)

        this.subscriptions.push(
            this.router.events.pipe(filter(e => e instanceof NavigationStart))
                .subscribe((e: NavigationStart) => {
                    if (this.isOpen) {
                        this.$modal.modal('hide')
                        $('.modal-backdrop').remove()
                    }
                })
        )
    }

    ngAfterViewInit() {
        this.$modal = $('#' + this.modalId)
        this.$modal.on('show.bs.modal', () => {
            this.isOpen = true
            this.safeDetectChanges()
        })

        this.$modal.on('hidden.bs.modal', () => {
            this.onClose.emit()
            this.isOpen = false
            this.safeDetectChanges()
        })

        $('body').append(this.$modal)
    }

    ngOnDestroy() {
        this.isDestroyed = true
        this.subscriptions.forEach(s => s.unsubscribe())

        if (!this.$modal) { return }

        if (!this.isOpen) {
            this.$modal.remove()
        } else {
            this.$modal.on('hidden.bs.modal', () => { this.$modal.remove() })
        }
    }

    buttonFunction(btn: any) {
        if (typeof btn.action === 'function') {
            btn.action()
        }
    }

    private safeDetectChanges() {
        if (!this.isDestroyed) {
        this.cdRef.detectChanges()
        }
    }

    private randomString(length: number) {
        const possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ123456789abcdefghijklmnopqrstuvwxyz'

        let text = ''
        for (let i = 0; i < length; i++) {
            text += possible.charAt(Math.floor(Math.random() * possible.length))
        }

        return text
    }
}
