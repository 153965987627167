import { Injectable } from '@angular/core'

declare var $: any

export class ModalConfiguration {
  id: string
  onShow?: () => void
  onShown?: () => void
  onHide?: () => void
  onHidden?: () => void
  closeOnEscape?: boolean = true
  closeWhenClickOutside?: boolean = true
}

@Injectable()
export class ModalService {

  private modals: { id: string, $modal: any }[] = []

  constructor ( ) { }

  public show(configOrId: ModalConfiguration | string) {
    let config: ModalConfiguration = <ModalConfiguration>configOrId
    if (typeof config === 'string') {
      const id = config
      config = new ModalConfiguration
      config.id = id
    }

    const $modal = $('#' + config.id)

    this.modals.push({ id: config.id, $modal })

    $modal.modal({ keyboard: !!config.closeOnEscape, backdrop: config.closeWhenClickOutside ? true : 'static', focus: false })

    this.registerModalEventListener(config.id, 'show.bs.modal', config.onShow)
    this.registerModalEventListener(config.id, 'shown.bs.modal', config.onShown)
    this.registerModalEventListener(config.id, 'hide.bs.modal', config.onHide)
    this.registerModalEventListener(config.id, 'hidden.bs.modal', config.onHidden)

    this.registerModalEventListener(config.id, 'hidden.bs.modal', () => this.hide(config))
  }

  public hide(configOrId: ModalConfiguration | string) {
    let config: ModalConfiguration = <ModalConfiguration>configOrId
    if (typeof config === 'string') {
      config = { id: config }
    }

    const $modal = this.get$modal(config.id)
    if (!$modal) { return }

    $modal.modal('hide')

    const index = this.modals.indexOf(this.modals.find(m => m.id === config.id))
    if (index !== -1) {
      this.modals.splice(index, 1)
    }

    this.removeModalEventListener(config.id, 'show.bs.modal', config.onShow)
    this.removeModalEventListener(config.id, 'shown.bs.modal', config.onShown)
    this.removeModalEventListener(config.id, 'hide.bs.modal', config.onHide)
    this.removeModalEventListener(config.id, 'hidden.bs.modal', config.onHidden)
  }

  public anyModalVisible(): boolean {
    return !!this.modals.length
  }

  private registerModalEventListener(id: string, event: string, listener?: () => void): void {
    if (!listener) { return }

    const $modal = this.get$modal(id)
    if (!$modal) { return }

    $modal.on(event, listener)
  }

  private removeModalEventListener(id: string, event: string, listener?: () => void): void {
    if (!listener) { return }

    const $modal = this.get$modal(id)
    if (!$modal) { return }

    $modal.off(event, listener)
  }

  private get$modal(id: string): any {
    const modal = this.modals.find(m => m.id === id)
    if (!modal) { return null }

    return modal.$modal
  }
}
