import { Component, OnInit } from '@angular/core'
import { UserWebService } from 'src/services/web-services/user-web.service'
import { ModalService } from 'src/services/modal-service'
import { DownloadsService } from 'src/services/downloads.service'
import { faTwitter, faInstagram, faTwitch, faGithub, faXing, faLinkedin } from '@fortawesome/free-brands-svg-icons'

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss']
})
export class LayoutComponent implements OnInit {
  public faTwitter = faTwitter
  public faInstagram = faInstagram
  public faTwitch = faTwitch
  public faGithub = faGithub
  public faXing = faXing
  public faLinkedin = faLinkedin

  public routerLinkActiveOptions: any = {
    matrixParams: 'ignored',
    queryParams: 'ignored',
    fragment: 'ignored'
  }

  public downloadsNavigation: Map<any, any>

  public loginUsername: string = 'user'
  public loginPassword: string = 'pass'

  public isLoggedIn: boolean = false
  public loggingIn: boolean = false

  public navigationItems = [
    {
      text: 'Home',
      url: '/home'
    },
    {
      text: 'Datenschutz',
      url: '/privacy'
    },
    {
      text: 'Impressum',
      url: '/imprint'
    }
  ]

  public loginModalId: string
  public loginButtons = [ ]

  public startYear: number = 2019
  public currentYear: string

  constructor(
    private userWebService: UserWebService,
    private modalService: ModalService,
    private downloadService: DownloadsService
  ) { }

  ngOnInit() {
    // this.reloadLogonState()
    this.loadDownloadsNavigation()

    this.loadYear()
  }

  private loadYear() {
    const currentYear = new Date().getFullYear()

    if (currentYear > this.startYear) {
      this.currentYear = `${this.startYear}-${currentYear}`
    } else {
      this.currentYear = `${currentYear}`
    }
  }

  public loginClicked() {
    this.resetLoginVariables()
    this.modalService.show(this.loginModalId)
  }

  private loadDownloadsNavigation() {
    this.downloadService.getDownloadsList()
      .then(downloads => {
        this.downloadsNavigation = this.groupBy(downloads, dl => dl.Category)
      })
  }

  private reloadLogonState() {
    this.userWebService.isLoggedIn()
      .then(loggedIn => this.isLoggedIn = loggedIn)
  }

  private executeLogin() {
    console.log(this.loginUsername)
    console.log(this.loginPassword)

    this.loggingIn = true

    this.userWebService.executeLogin(this.loginUsername, this.loginPassword)
      .then(user => {
        console.log(user)
        this.loggingIn = false

        if (user) {
          this.modalService.hide(this.loginModalId)
        }
      })
  }

  private cancelLogin() {
    this.resetLoginVariables()

    this.modalService.hide(this.loginModalId)
  }

  private resetLoginVariables() {
    this.loginUsername = ''
    this.loginPassword = ''

    this.loggingIn = false
  }

  private groupBy(list, keyGetter) {
    const map = new Map()
    list.forEach((item) => {
         const key = keyGetter(item)
         const collection = map.get(key)
         if (!collection) {
             map.set(key, [item])
         } else {
             collection.push(item)
         }
    })

    return map
  }

}
