import { Injectable } from '@angular/core'
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders, HttpParams } from '@angular/common/http'
import { map } from 'rxjs/operators'

@Injectable()
export abstract class BaseWebService {

    apiUrl: string = 'https://api.berger-media.biz/api.php'

    constructor(
        private http: HttpClient
    ) { }

    public getVoid(): Promise<void> {
        return this.http.get(this.apiUrl)
        .pipe(map(() => {}))
        .toPromise()
    }

    public postVoid(data: any): Promise<void> {
        return this.http.post(this.apiUrl, JSON.stringify(data), { responseType: 'text', reportProgress: false, headers: this.getHeaders() })
        .pipe(map(() => {}))
        .toPromise()
    }

    postAndReturnList<TData>(data: any): Promise<TData[]> {
        const req = new HttpRequest('POST', this.apiUrl, JSON.stringify(data), this.requestOptions)
        return this.http.request<TData[]>(req)
        .pipe(map((res: HttpResponse<TData[]>) => res.body ))
        .toPromise()
    }

    getList<TData>(): Promise<TData[]> {
        const req = new HttpRequest('GET', this.apiUrl, this.requestOptions)
        return this.http.request<TData[]>(req)
        .pipe(map((res: HttpResponse<TData[]>) => res.body))
        .toPromise()
    }

    postAndReturn<TData>(data: any): Promise<TData> {
        const req = new HttpRequest('POST', this.apiUrl, JSON.stringify(data), this.requestOptions)

        return this.http.request<TData>(req)
        .pipe(map((res: HttpResponse<TData>) => res.body))
        .toPromise()
    }

    getSingle<TData>(): Promise<TData> {
        const req = new HttpRequest('GET', this.apiUrl, this.requestOptions)
        return this.http.request<TData>(req)
        .pipe(map((res: HttpResponse<TData>) => res.body))
        .toPromise()
    }

    postAndReturnNumber(data: any): Promise<number> {
        const req = new HttpRequest('POST', this.apiUrl, JSON.stringify(data), this.requestOptions)
        return this.http.request<number>(req)
        .pipe(map((res: HttpResponse<number>) => res.body))
        .toPromise()
        .then(count => count || 0)
    }

    postAndReturnString(data: any): Promise<string> {
        const req = new HttpRequest('POST', this.apiUrl, JSON.stringify(data), this.requestOptions)
        return this.http.request<string>(req)
        .pipe(map((res: HttpResponse<string>) => {
            if (!res.body) { return res.body }
            if (res.body === 'null') { return null }
            return res.body.slice(1, -1)
        }))
        .toPromise()
    }

    getString(data: any): Promise<string> {
        const requestOptions = { headers: this.getHeaders(), params: {} }

        if (data) {
        let params = new HttpParams()
        Object.keys(data).forEach(key => {
            params = params.set(key, data[key])
        })
        requestOptions.params = params
        }

        const req = new HttpRequest('GET', this.apiUrl, requestOptions)
        return this.http.request<string>(req)
        .pipe(map((res: HttpResponse<string>) => {
            if (!res.body) { return res.body }
            if (res.body === 'null') { return null }
            return res.body.slice(1, -1)
        }))
        .toPromise()
    }

    private getHeaders(): HttpHeaders {
        let headers = new HttpHeaders()

        headers = headers
            .set('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8')

        return headers
    }

    private get requestOptions() {
        return {
            observe: 'response',
            reportProgress: false,
            enableCache: false,
            headers: this.getHeaders()
        }
    }
}
