import { NgModule } from '@angular/core'
import { Routes, RouterModule } from '@angular/router'
import { LayoutComponent } from './layout/layout.component'
import { HomeComponent } from './home/home.component'
import { PrivacyComponent } from './privacy/privacy.component'
import { ImprintComponent } from './imprint/imprint.component'

const routes: Routes = [
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full'
  },
  {
    path: '',
    component: LayoutComponent,
    children: [
      {
        path: 'home',
        component: HomeComponent
      },
      {
        path: 'privacy',
        component: PrivacyComponent
      },
      {
        path: 'imprint',
        component: ImprintComponent
      },
      {
        path: 'downloads',
        loadChildren: () => import('./downloads/downloads.module').then(m => m.DownloadsModule)
      },
      {
        path: '**',
        redirectTo: 'home'
      }
    ]
  }
]

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
