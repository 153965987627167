import { Component } from '@angular/core'
import { Router } from '@angular/router'
import { Injectable, Injector } from '@angular/core'
import { HttpClient, HttpHeaders } from '@angular/common/http'
import { NewsConfig } from './news-data.model'

@Component({
    selector: 'app-news',
    templateUrl: './news.component.html',
    styleUrls: ['./news.component.scss']
})
export class NewsComponent {
    public newsRoute: string
    public currentNews: string

    constructor(
        private router: Router,
        private injector: Injector
    ) {
        this.init()
     }

    get hasNews(): boolean {
        return (this.currentNews || '').length > 0
    }

    newsLinkClicked() {
        if (!this.hasNews || (this.newsRoute || '').length === 0) {
            return
        }

        this.router.navigate([this.newsRoute])
    }

    init() {
        const http = this.injector.get(HttpClient)
        // Do not cache
        const headers = new HttpHeaders()
        .set('Cache-Control', 'no-cache, no-store, must-revalidate')
        .set('Pragma', 'no-cache')
        .set('Expires', 'Sat, 01 Jan 2000 00:00:00 GMT')

        return http.get('news-data.json', { headers: headers })
            .toPromise()
            .then(res => {
                const loadedData = res as NewsConfig
                if (!!loadedData) {
                    this.newsRoute = loadedData.newsRoute
                    this.currentNews = loadedData.newsText
                }
            }
        )
    }
}
