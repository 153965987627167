 import { faDownload, faGlobe } from '@fortawesome/free-solid-svg-icons'
 import { faGithub } from '@fortawesome/free-brands-svg-icons'
import { ExtraType } from './ExtraType'

export class DetailsExtra {
    public Type: ExtraType
    public Class: string
    public Value: string
    public Other: string

    public getButtonIcon() {
        if (this.Type !== ExtraType.Button) {
            return undefined
        }

        switch (this.Other) {
            case 'Download': return faDownload
            case 'Quellcode': return faGithub
            case 'Homepage': return faGlobe
            default: return undefined
        }
    }
}
