import { Component, OnInit } from '@angular/core'
import { Injector } from '@angular/core'
import { HttpClient, HttpHeaders } from '@angular/common/http'
import { ImprintValidSites } from './imprint-valid-sites.model'

@Component({
  selector: 'app-imprint',
  templateUrl: './imprint.component.html',
  styleUrls: [ './imprint.component.scss' ]
})

export class ImprintComponent implements OnInit {
  public additionalValidSites: ImprintValidSites[]

  constructor(
    private injector: Injector
  ) { }

  ngOnInit() {
    this.init()
  }

  init() {
    const http = this.injector.get(HttpClient)
    // Do not cache
    const headers = new HttpHeaders()
    .set('Cache-Control', 'no-cache, no-store, must-revalidate')
    .set('Pragma', 'no-cache')
    .set('Expires', 'Sat, 01 Jan 2000 00:00:00 GMT')

    return http.get('imprint-valid-sites.json', { headers: headers })
        .toPromise()
        .then(res => {
            const loadedData = res as ImprintValidSites[]
            if (!!loadedData) {
                this.additionalValidSites = loadedData
            } else {
              this.additionalValidSites = undefined
            }
        }
    )
}

}
