<header class="navbar navbar-expand-lg navbar-dark">
  <a class="navbar-brand" routerLink="/"><div class="logo"></div></a>
  <button class="navbar-toggler me-4" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button>
  <div class="collapse navbar-collapse" id="navbarNavDropdown">
    <ul class="navbar-nav">
      <li *ngFor="let navItem of navigationItems" class="nav-item" data-bs-toggle="collapse" data-target=".navbar-collapse.show">
        <a class="nav-link" routerLinkActive="active" [routerLink]="navItem.url">{{navItem.text}}</a>
      </li>
      <li *ngIf="!!downloadsNavigation" class="nav-item dropdown" routerLinkActive="active" data-bs-toggle="collapse" data-target=".navbar-collapse.show">
        <a class="nav-link dropdown-toggle" [routerLink]="['./downloads']" routerLinkActive="active" [routerLinkActiveOptions]="routerLinkActiveOptions" [disableLink]="true" id="downloadsMenuDropdown" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Downloads</a>
        <div class="dropdown-menu" aria-labelledby="downloadsMenuDropdown">
          <ng-container *ngFor="let nav of downloadsNavigation | keyvalue">
            <h6 class="dropdown-header">{{ nav.key }}</h6>
            <a *ngFor="let link of nav.value" class="dropdown-item" [routerLink]="link.Link" routerLinkActive="active" [routerLinkActiveOptions]="routerLinkActiveOptions">{{ link.Name }}</a>
          </ng-container>
        </div>
      </li>
    </ul>
    <!--<ul class="nav navbar-nav ml-auto">
      <li *ngIf="!isLoggedIn">
        <a class="nav-link" [routerLink]="" (click)="loginClicked()">Login</a>
      </li>
    </ul>-->
  </div>
</header>

<div class="app-body">
  <main class="main">
    <div class="container-fluid">
      <router-outlet></router-outlet>
    </div>
  </main>
</div>

<footer>
  <div class="row">
    <div class="col-12 col-sm-12 col-md-12 col-lg-4 col-xs-4">
      <ul>
        <li class="social-twitter"><a href="https://twitter.com/UltimateG4ming" target="_blank" rel="noopener"><fa-icon [icon]="faTwitter"></fa-icon></a></li>
        <li class="social-instagram"><a href="https://www.instagram.com/floo_berg/" target="_blank" rel="noopener"><fa-icon [icon]="faInstagram"></fa-icon></a></li>
        <li class="social-twitch"><a href="https://www.twitch.tv/Ultim4teG4ming" target="_blank" rel="noopener"><fa-icon [icon]="faTwitch"></fa-icon></a></li>
        <!-- <li class="social-bitbucket"><a href="https://bitbucket.org/Florian_Berger/" target="_blank" rel="noopener"><i class="fa fa-bitbucket"></i></a></li> -->
        <li class="social-github"><a href="https://github.com/florian-berger/" target="_blank" rel="noopener"><fa-icon [icon]="faGithub"></fa-icon></a></li>
        <li class="social-xing"><a href="https://www.xing.com/profile/Florian_Berger144/" target="_blank" rel="noopener"><fa-icon [icon]="faXing"></fa-icon></a></li>
        <li class="social-linkedin"><a href="https://www.linkedin.com/in/florian-berger-a36ab9192/" target="_blank" rel="noopener"><fa-icon [icon]="faLinkedin"></fa-icon></a></li>
      </ul>
    </div>
    <div class="col-12 col-sm-12 col-md-12 col-lg-4 col-xs-4" style="text-align: center; padding-top: 5px;">
      <a [routerLink]="'/privacy'">Datenschutz</a> | <a [routerLink]="'/imprint'">Impressum</a>
    </div>
    <div class="col-12 col-sm-12 col-md-12 col-lg-4 col-xs-4" style="padding-top: 5px;">
      <span class="float-end">&copy; {{ currentYear }} Florian Berger</span>
    </div>
  </div>
</footer>

<app-modal [title]="'Login'" [buttons]="loginButtons" (modalIdChange)="loginModalId = $event">
  <ng-template appModalBody>
    <form (ngSubmit)="false" id="loginForm">
      <div class="form-group">
        <label for="username">Benutzername</label>
        <input type="text" class="form-control" [value]="loginUsername" (input)="loginUsername = $event.target.value" />
      </div>
      <div class="form-group">
        <label for="password">Passwort</label>
        <input type="password" class="form-control" [value]="loginPassword" (input)="loginPassword = $event.target.value" />
      </div>
      <div class="form-group">
        <button type="button" [disabled]="loggingIn" class="btn btn-primary" (click)="executeLogin()">Login</button>
      </div>
    </form>
  </ng-template>
</app-modal>