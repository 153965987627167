import { Injectable } from '@angular/core'
import { BaseWebService } from './base-web.service'
import { HttpClient } from '@angular/common/http'
import { User } from 'src/data/user'

@Injectable()
export class UserWebService extends BaseWebService {
    constructor(
        httpClient: HttpClient
    ) {
        super(httpClient)
    }

    public getUser(): Promise<User> {
        return this.postAndReturn<User>({
            'type': 'member',
            'action': 'getCurrentUser'
        })
    }

    public isLoggedIn(): Promise<boolean> {
        return this.postAndReturn({
            'type': 'member',
            'action': 'getCurrentUser'
        }).then(r => {
            console.log(r)
            return !!r
        })
    }

    public executeLogin(username: string, password: string): Promise<User> {
        return this.postAndReturn<{result: number}>({
            'type': 'member',
            'action': 'authenticate',
            'user': username,
            'pass': password
        }).then(r => {
            console.log(r)
            if (!r || r.result !== 1) {
                return undefined
            }

            return this.getUser()
        })
    }
}
