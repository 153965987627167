<app-news></app-news>

<div class="container-content" style="padding-top: 1px;">
  <div class="card bg-light mb-3 mt-3">
    <div class="card-header bg-primary text-white font-weight-bold">Diese Webseite wird betrieben durch</div>
    <div class="card-body">
      <p class="card-text">
        Florian Berger<br />
        c/o COCENTER<br />
        Koppoldstr. 1<br />
        86551 Aichach<br />
        <br />
        <span class="font-weight-bold">Kontakt:</span><br />
        E-Mail: webmaster@berger-media.biz<br />
      </p>
      <div class="alert alert-warning mb-0 text-justify" role="alert">
        Die Nutzung der im Rahmen der Impressumspflicht veröffentlichten Kontaktdaten durch Dritte zur Übermittlung von nicht ausdrücklich angeforderter Werbung und Informationsmaterialien wird hiermit ausdrücklich untersagt! Der Betreiber der Seite behält sich ausdrücklich rechtliche Schritte im Falle der unverlangten Zusendung von Werbeinformationen, z.B. per E-Mail oder Post, vor.
      </div>
    </div>
  </div>

  <div class="card bg-light mb-3" *ngIf="additionalValidSites && additionalValidSites.length > 0">
    <div class="card-header bg-primary text-white font-weight-bold">Weitere Seiten</div>
    <div class="card-body">
      <p class="card-text text-justify">
        Dieses Impressum ist ebenfalls gültig für die folgenden Seiten:<br />
      </p>
      <ul style="list-style: square;">
        <li *ngFor="let site of additionalValidSites">
          <a [href]="site.siteUri" target="_blank" rel="noopener">{{site.display}}</a>
        </li>
      </ul>
    </div>
  </div>
  
  <div class="card bg-light mb-3">
    <div class="card-header bg-primary text-white font-weight-bold">Haftung für Inhalte</div>
    <div class="card-body">
      <p class="card-text text-justify">
        Als Diensteanbieter sind wir für eigene Inhalte auf diesen Seiten nach den allgemeinen Gesetzen verantwortlich. Wir sind als Diensteanbieter jedoch nicht verpflichtet, übermittelte oder gespeicherte fremde Informationen zu überwachen oder nach Umständen zu forschen, die auf eine rechtswidrige Tätigkeit hinweisen.<br />
        <br />
        Verpflichtungen zur Entfernung oder Sperrung der Nutzung von Informationen nach den allgemeinen Gesetzen bleiben hiervon unberührt. Eine diesbezügliche Haftung ist jedoch erst ab dem Zeitpunkt der Kenntnis einer konkreten Rechtsverletzung möglich. Bei Bekanntwerden von entsprechenden Rechtsverletzungen werden wir diese Inhalte umgehend entfernen.
      </p>
    </div>
  </div>
  
  <div class="card bg-light mb-3">
    <div class="card-header bg-primary text-white font-weight-bold">Haftung für Links</div>
    <div class="card-body">
      <p class="card-text text-justify">
        Unser Angebot enthält Links zu externen Websites Dritter, auf deren Inhalte wir keinen Einfluss haben. Deshalb können wir für diese fremden Inhalte auch keine Gewähr übernehmen. Für die Inhalte der verlinkten Seiten ist stets der jeweilige Anbieter oder Betreiber der Seiten verantwortlich. Die verlinkten Seiten wurden zum Zeitpunkt der Verlinkung auf mögliche Rechtsverstöße überprüft. Rechtswidrige Inhalte waren zum Zeitpunkt der Verlinkung nicht erkennbar.<br />
        <br />
        Eine permanente inhaltliche Kontrolle der verlinkten Seiten ist jedoch ohne konkrete Anhaltspunkte einer Rechtsverletzung nicht zumutbar. Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Links umgehend entfernen.
      </p>
    </div>
  </div>
  
  <div class="card bg-light mb-3">
    <div class="card-header bg-primary text-white font-weight-bold">Urheberrecht</div>
    <div class="card-body">
      <p class="card-text text-justify">
        Die durch die Seitenbetreiber erstellten Inhalte und Werke auf diesen Seiten unterliegen dem deutschen Urheberrecht. Die Vervielfältigung, Bearbeitung, Verbreitung und jede Art der Verwertung außerhalb der Grenzen des Urheberrechtes bedürfen der schriftlichen Zustimmung des jeweiligen Autors bzw. Erstellers. Downloads und Kopien dieser Seite sind nur für den privaten, nicht kommerziellen Gebrauch gestattet.<br />
        <br />
        Soweit die Inhalte auf dieser Seite nicht vom Betreiber erstellt wurden, werden die Urheberrechte Dritter beachtet. Insbesondere werden Inhalte Dritter als solche gekennzeichnet. Sollten Sie trotzdem auf eine Urheberrechtsverletzung aufmerksam werden, bitten wir um einen entsprechenden Hinweis. Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Inhalte umgehend entfernen.
      </p>
    </div>
  </div>
  
  <div class="card bg-light mb-3">
    <div class="card-header bg-primary text-white font-weight-bold">Information</div>
    <div class="card-body">
      <p class="card-text text-justify">
        Quelle: Erstellt mit dem Impressum-Generator von <a href="https://www.e-recht24.de/impressum-generator.html">e-recht24.de</a>. Vom Webseitenbetreiber angepasst.
      </p>
    </div>
  </div>
</div>
