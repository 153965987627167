<div class="modal" id="{{ modalId }}" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document" [class.modal-lg]="largeModal">
      <div class="modal-content" [style.background-color]="'white'">
        <div *ngIf="title || titleTemplate" class="modal-header">
          <h5 *ngIf="title && !titleTemplate" class="modal-title">{{ title }}</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
  
          <ng-template *ngIf="titleTemplate" [ngTemplateOutlet]="titleTemplate?.templateRef"></ng-template>
        </div>

        <div class="modal-body">
          <span *ngIf="text && !bodyTemplate"></span>

          <ng-template *ngIf="bodyTemplate && isOpen" [ngTemplateOutlet]="bodyTemplate?.templateRef"></ng-template>
        </div>

        <div *ngIf="buttons && footerTemplate" class="modal-footer">
          <ng-template *ngIf="footerTemplate" [ngTemplateOutlet]="footerTemplate?.templateRef"></ng-template>
  
          <ng-container *ngFor="let b of buttons">
            <button
              type="button"
              [class]="'btn ' + b.styleClass"
              [attr.data-dismiss]="(b.dismiss || b.dismiss === undefined) ? 'modal' : ''"
              (click)="buttonFunction(b)"
              [class.btn-primary]="b.primary"
              [class.btn-secondary]="!b.primary"
              >{{ b.text }}</button>
          </ng-container>
        </div>
      </div>
    </div>
  </div>