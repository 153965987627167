<app-news></app-news>

<section class="wrapper section-a">
  <div class="row mx-5">
    <div class="col col-2 col-md-2">
      <img class="circle-img-200 float-end" src="img/Owner.png">
    </div>

    <div class="col col-10 col-md-10">
      <h3>Wer bin ich?</h3>
      <p class="text-justify">
        Ich bin Flo, 26 Jahre jung und Betreiber dieser Seite. Ich arbeite als Software-Entwickler und beschäftige mich auch in der Freizeit mit Software-Entwicklung.
        Ich entwickel mit folgenden Programmier-, Auszeichnungs- und Scriptsprachen:
      </p>
      <ul>
        <li>C# (WPF, Console/Server, WinForms)</li>
        <li>JavaScript/TypeScript (Angular)</li>
        <li>Xamarin.Forms &amp; .NET MAUI</li>
        <li>PHP</li>
        <li>Java</li>
      </ul>
      <p class="text-justify">
        Eine Sammlung aller meiner Projekte ist in der Navigation dieser Seite sowie in meinem GitHub-Account zu finden.
      </p>
    </div>
  </div>
</section>

<section class="wrapper section-b">
  <div class="row mx-5 mt-3">
    <div class="col-12">
      <h3>Software</h3>
      <p class="text-justify">
        Was für Arten von Software entwickel ich? So einfach ist das nicht zu beantworten - angefangen hat alles im Alter von 12 Jahren. Ein Freund und ich riefen eine kleine Community ins Leben, in der wir unter anderem über ein <span class="font-weight-bold">MyBB-Forum</span> kommunizierten. Allerdings waren die Möglichkeiten dieser Software recht stark beschränkt, weswegen ich mich in die Entwicklung von Plugins eingearbeitet habe. Sie sind auch noch auf <a href="https://community.mybb.com/mods.php?action=profile&uid=75209" target="_blank" rel="noopener">mybb.com</a> verfügbar, werden aktuell aber nicht weiter gepflegt.<br />
        <br />
        Das war mein erster Kontakt mit der Software-Entwicklung. Weiter ging es mit verschiedenen kleinen Tools, die ich für mich selbst entwickelte. So entwickelte ich ein kleines <span class="font-weight-bold">Erinnerungen-Tool</span>, welches mich zu individuell konfigurierbaren Zeiten durch ein Popup mit frei definierbaren Texten erinnerte. Ein weiteres Programm ließ mich verschiedene Streams von Online-Radios konfigurieren und diese dann auch aufzeichnen.<br />
        <br />
        Wenige Jahre später startete ich eine Ausbildung zum Fachinformatiker für Anwendungsentwicklung, die ich mit einem guten Abschluss beendete. Diesen Beruf übe ich noch heute mit Freude aus und hoffentlich ist mir dies auch noch einige Jahre möglich.
      </p>
    </div>
  </div>
</section>

<section class="wrapper section-a">
  <div class="row mx-5">
    <div class="col-10 col-md-10">
      <h3>Gaming</h3>
      <p class="text-justify">
        Im Gaming-Bereich kennt man mich unter dem Namen <span class="font-weight-bold">UltimateG4ming</span>. Ich spiele die verschiedensten Spiele, was von Simulationen über Sport-Spiele bis hin zu Shootern reicht.<br />
        Während der Gamescom 2018 hatten zwei Freunde und ich die Idee, einen Blog zum Thema Gaming aufzubauen. Im Laufe der Woche in Köln haben wir uns ein paar Gedanken gemacht und eine erste Webseite mit dem Namen <span class="font-weight-bold">ParagonGamers</span> aufgebaut. Dort berichteten wir bis zum Januar 2021 leider eher unregelmäßig über aktuelle Neuigkeiten in der Spiele-Welt und testeten neue Erscheinungen, eher wir uns für die Einstellung der Website entschieden haben.<br />
        <br />
        Gelegentlich bin ich mit Streams auf <a target="_blank" rel="noopener" href="https://twitch.tv/ultim4teg4ming">twitch.tv/Ultim4teG4ming</a> online. Doch auch dies ist aktuell leider eher unregelmäßig der Fall.
      </p>
    </div>
    <div class="col-2 col-md-2">
      <img class="circle-img-200 float-start" src="img/Gaming.png">
    </div>
  </div>
</section>
