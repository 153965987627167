import { BrowserModule } from '@angular/platform-browser'
import { NgModule } from '@angular/core'
import { AppRoutingModule } from './app-routing.module'
import { AppComponent } from './app.component'
import { LayoutComponent } from './layout/layout.component'
import { HomeComponent } from './home/home.component'
import { PrivacyComponent } from './privacy/privacy.component'
import { ImprintComponent } from './imprint/imprint.component'
import { NgbModule } from '@ng-bootstrap/ng-bootstrap'
import { HttpClientModule } from '@angular/common/http'
import { UserWebService } from 'src/services/web-services/user-web.service'
import { ModalComponent, AppModalTitleDirective, AppModalBodyDirective, AppModalFooterDirective } from './shared/modal/modal.component'
import { ModalService } from 'src/services/modal-service'
import { NewsComponent } from './news/news.component'
import { DownloadsService } from 'src/services/downloads.service'
import { SharedModule } from './shared/shared.module'

@NgModule({
  declarations: [
    AppComponent,
    LayoutComponent,
    HomeComponent,
    PrivacyComponent,
    ImprintComponent,
    ModalComponent,
    AppModalTitleDirective,
    AppModalBodyDirective,
    AppModalFooterDirective,
    NewsComponent
  ],
  imports: [
    NgbModule,
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    SharedModule.forRoot()
  ],
  providers: [
    UserWebService,
    DownloadsService,
    ModalService
  ],
  exports: [
    ModalComponent,
    NgbModule,
    AppModalTitleDirective,
    AppModalBodyDirective,
    AppModalFooterDirective
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
